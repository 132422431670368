import { Component } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  inputString: string;
  outputString: string;
  activeuppercase: boolean = false;
  activelowercase: boolean = false;
  activecamelcase: boolean = false;
  activetitlecase: boolean = false;
  activecapitalscase: boolean = false;

  selectedButton: string = "";


  constructor(private clipboard: Clipboard) { }

  onChangeEvent(event: any) {
    this.convertToCamelCase();
  }

  convertToCamelCase() {
    const words = this.inputString.split(' ');

    if (this.selectedButton == "texttouppercase") {
      const camelCaseWords = words.map((word, index) => {
        return word.toUpperCase();
      });
      this.outputString = camelCaseWords.join('');
    } else if (this.selectedButton == "texttolowercase") {
      const camelCaseWords = words.map((word, index) => {
        return word.toLowerCase();
      });

      this.outputString = camelCaseWords.join('');
    } else if (this.selectedButton == "texttotitle") {

      for (let i = 0; i < words.length; i++) {
        let word = words[i];
        words[i] = word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      }
      

      this.outputString = words.join(' ');
    }else if (this.selectedButton == "texttocapitals") {
      let result = "";
      for (let i = 0; i < this.inputString.length; i++) {
        let char = this.inputString.charAt(i);
        if (char === char.toUpperCase()) {
          result += char.toLowerCase();
        } else {
          result += char.toUpperCase();
        }
      }
      

      this.outputString = result;
    } else {
      const camelCaseWords = words.map((word, index) => {
        if (index === 0) {
          return word.toLowerCase();
        } else {
          return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
        }
      });
      this.outputString = camelCaseWords.join('');
    }

  }

  download() {
    const filename = "text.txt";
    const blob = new Blob([this.outputString], { type: 'text/plain' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }

  copy() {
    this.clipboard.copy(this.outputString);
  }

  texttouppercase(button: string) {
    this.activeuppercase = true;
    this.selectedButton = "texttouppercase";
    this.convertToCamelCase();
  }

  texttolowercase(button: string) {
    this.activelowercase = true;
    this.selectedButton = "texttolowercase";
    this.convertToCamelCase();
  }
  texttocamelcase(button: string) {
    this.activecamelcase = true;
    this.selectedButton = "texttocamelcase";
    this.convertToCamelCase();
  }
  texttotitle(button: string) {
    this.activetitlecase = true;
    this.selectedButton = "texttotitle";
    this.convertToCamelCase();
  }
  texttocapitals(button: string) {
    this.activecapitalscase = true;
    this.selectedButton = "texttocapitals";
    this.convertToCamelCase();

  }

  buttonColor(button: string) {
    debugger;
    if (button === this.selectedButton) {
      return "green";
    } else {
      return "red";
    }
  }

  clear() {
    debugger;
    this.outputString = "";
    this.inputString = "";
;  }
}
