import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-brands',
  templateUrl: './brands.component.html',
  styleUrls: ['./brands.component.css']
})
export class BrandsComponent implements OnInit {

  brandname: string[] = ['apple', 'banana', 'orange'];
  brandimage: string[] = ['apple', 'banana', 'orange'];
  brandpdf: string[] = ['apple', 'banana', 'orange'];

  brands : Brand[]= [];

  constructor() { }

  ngOnInit(): void {

    var brnd = new Brand();

    brnd.name = "Adidas";
    brnd.image = "adidas.png";
    brnd.pdf = "Adidas Catlogue 2022.pdf";

   this.brands.push(brnd);

   var brnd = new Brand();

   brnd.name = "UNITED COLORS OF BENETTON";
   brnd.image = "benetton.png";
   brnd.pdf = "BENETTON COLLECTIONS - New.pdf";

  this.brands.push(brnd);

  var brnd = new Brand();

  brnd.name = "Blaupunkt";
  brnd.image = "Blaupunkt.jpg";
  brnd.pdf = "Blaupunkt .pdf";

 this.brands.push(brnd);

 var brnd = new Brand();

 brnd.name = "boat";
 brnd.image = "boat.png";
 brnd.pdf = "boat catalogue-LR.pdf";

this.brands.push(brnd);

var brnd = new Brand();

brnd.name = "CANTABIL";
brnd.image = "CANTABIL.NS_BIG-6d1e382a.png";
brnd.pdf = "Cantabil All Products - MRP Reseller.pdf";

this.brands.push(brnd);

var brnd = new Brand();

brnd.name = "Doctor Towels";
brnd.image = "doctortowel.png";
brnd.pdf = "Doctor Towels E-Catalogue__.pdf";

this.brands.push(brnd);

var brnd = new Brand();

brnd.name = "FUZO";
brnd.image = "fuzo.png";
brnd.pdf = "FUZO Corporate Gifts Catalogue - Jul 2022.pdf";

this.brands.push(brnd);

var brnd = new Brand();

brnd.name = "Happilo";
brnd.image = "happilo.png";
brnd.pdf = "Happilo Product Range Catalog - with prices.pdf";

this.brands.push(brnd);

var brnd = new Brand();

brnd.name = "Mark  & Spencer";
brnd.image = "mark spencer.jpg";
brnd.pdf = "m&s new catalogue.pdf";

this.brands.push(brnd);

var brnd = new Brand();

brnd.name = "mCaffeine";
brnd.image = "mcaffeini.png";
brnd.pdf = "mCaffeine Single Catalogue..pdf";

this.brands.push(brnd);

var brnd = new Brand();

brnd.name = "Mona B";
brnd.image = "mona b.jpg";
brnd.pdf = "Mona B Catalog 2023-1.pdf";

this.brands.push(brnd);

var brnd = new Brand();

brnd.name = "Noise";
brnd.image = "noise-800x400.jpg";
brnd.pdf = "Noise-Diwali-2022_compressed.pdf";

this.brands.push(brnd);

var brnd = new Brand();

brnd.name = "PUMA";
brnd.image = "puma.jpg";
brnd.pdf = "PUMA CATALOGUE 23.pdf";

this.brands.push(brnd);

var brnd = new Brand();

brnd.name = "Skechers";
brnd.image = "Skechers.png";
brnd.pdf = "Skechers Catalog.pdf";

this.brands.push(brnd);

var brnd = new Brand();

brnd.name = "Swiss Military";
brnd.image = "swiss military.jpg";
brnd.pdf = "SM_eCatalogue_April 2023.pdf";

this.brands.push(brnd);

var brnd = new Brand();

brnd.name = "Wildcraft";
brnd.image = "Wildcraf.png";
brnd.pdf = "Wildcraft Bags - Catalogue.pdf";

this.brands.push(brnd);

var brnd = new Brand();

brnd.name = "Pebble";
brnd.image = "pebble.png";
brnd.pdf = "Wildcraft Bags - Catalogue.pdf";

this.brands.push(brnd);

var brnd = new Brand();

brnd.name = "Hummel";
brnd.image = "hummel.png";
brnd.pdf = "DOC-20230426-WA0001..pdf";

this.brands.push(brnd);

var brnd = new Brand();

brnd.name = "pigeon";
brnd.image = "pigeon.png";
brnd.pdf = "Dining catalogue print.pdf";

this.brands.push(brnd);

var brnd = new Brand();

brnd.name = "US POLO";
brnd.image = "uspolo.png";
brnd.pdf = "Arvind MRP-9.pdf";

this.brands.push(brnd);

var brnd = new Brand();

brnd.name = "Welspun";
brnd.image = "Welspun.jpg";
brnd.pdf = "Welspun  .pdf";

this.brands.push(brnd);
  }

}

export class Brand {
  name: string;
  image: string;
  pdf: string;
}
