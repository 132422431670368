   
    <!-- Offcanvas Menu Begin -->
    <div class="offcanvas-menu-overlay"></div>
    <div class="offcanvas-menu-wrapper">
        <div class="offcanvas__option">
            <div class="offcanvas__links">
                <a href="mailto:kesar.jatin@gmail.com" style="text-transform: lowercase;"><span class="icon_mail_alt" ></span> &nbsp; kesar.jatin@gmail.com</a>
                <a href="#" > <img _ngcontent-tct-c10="" src="assets/img/whatsapp.png" style="width: 11%;"> &nbsp;+91 99990 72593</a>
            </div>
            <!-- <div class="offcanvas__top__hover">
                <span>Usd <i class="arrow_carrot-down"></i></span>
                <ul>
                    <li>USD</li>
                    <li>EUR</li>
                    <li>USD</li>
                </ul>
            </div> -->
        </div>
        <div class="offcanvas__nav__option">
            <a  href="https://wa.me/9999072593" target="_blank" class="getstarted scrollto primary-btn" style="
            padding: 13px;
            background: green;
            border-radius: 17px;
        "><img src="assets/img/whatsapp.png" style="width: 11%;">&nbsp;Get Started</a>
        </div>
        <div id="mobile-menu-wrap"></div>
        <!-- <div class="offcanvas__text">
            <p>Free shipping, 30-day return or refund guarantee.</p>
        </div> -->
    </div>
    <!-- Offcanvas Menu End -->
    
    <!-- Header Section Begin -->
    <header class="header">
        <div class="header__top">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6 col-md-7">
                        <!-- <div class="header__top__left">
                            <p>Free shipping, 30-day return or refund guarantee.</p>
                        </div> -->
                    </div>
                    <div class="col-lg-6 col-md-5">
                        <div class="header__top__right">
                            <div class="header__top__links">
                                <a href="mailto:kesar.jatin@gmail.com" style="text-transform: lowercase;"><span class="icon_mail_alt" ></span> &nbsp; kesar.jatin@gmail.com</a>
                                <a href="#" > <img _ngcontent-tct-c10="" src="assets/img/whatsapp.png" style="width: 11%;"> &nbsp;+91 99990 72593</a>
                            </div>
                            <!-- <div class="header__top__hover">
                                <span>Usd <i class="arrow_carrot-down"></i></span>
                                <ul>
                                    <li>USD</li>
                                    <li>EUR</li>
                                    <li>USD</li>
                                </ul>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="row">
                <div class="col-lg-3 col-md-3">
                    <div class="header__logo">
                        <a href="./index.html"><img src="assets/img/logo.png" alt=""></a>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6">
                    <nav class="header__menu mobile-menu">
                        <ul>
                            <li><a class="nav-link"  [routerLink] = "['/home']" routerLinkActive="active" >Home</a></li>
                            <li><a class="nav-link"  [routerLink] = "['/about']" routerLinkActive="active" >About</a></li>
                            <li><a class="nav-link"  [routerLink] = "['/brands']" routerLinkActive="active" >Brands</a></li>
                            <!-- <li><a href="#">Pages</a>
                                <ul class="dropdown">
                                    <li><a href="./about.html">About Us</a></li>
                                    <li><a href="./shop-details.html">Shop Details</a></li>
                                    <li><a href="./shopping-cart.html">Shopping Cart</a></li>
                                    <li><a href="./checkout.html">Check Out</a></li>
                                    <li><a href="./blog-details.html">Blog Details</a></li>
                                </ul>
                            </li> -->
                            <li><a href="./blog.html">Blog</a></li>
                            <li><a href="./contact.html">Contacts</a></li>
                        </ul>
                    </nav>
                </div>
                <div class="col-lg-3 col-md-3">
                    <div class="header__nav__option">
                        <a  href="https://wa.me/9999072593" target="_blank" class="getstarted scrollto primary-btn" style="
                        padding: 13px;
                        background: green;
                        border-radius: 17px;
                    "><img src="assets/img/whatsapp.png" style="width: 11%;">&nbsp;Get Started</a>
                    </div>
                </div>
            </div>
            <div class="canvas__open"><i class="fa fa-bars"></i></div>
        </div>
    </header>

    