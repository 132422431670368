import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CamelCaseComponent } from './camel-case/camel-case.component';
import { CommaSeperatorComponent } from './comma-seperator/comma-seperator.component';
import { HomeComponent } from './home/home.component';
import { ContactFormComponent } from './contact-form/contact-form.component';
import { BrandsComponent } from './/brands/brands.component';
import { ContactComponent } from './/contact/contact.component';
import { AboutComponent } from './/about/about.component';
import { MissionComponent } from './/mission/mission.component';

const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent },
  { path: 'brands', component: BrandsComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'about', component: AboutComponent },
  { path: 'mission', component: MissionComponent },

];

@NgModule({
  imports: [RouterModule.forRoot(routes,{ useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
