
<section class="breadcrumb-option">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="breadcrumb__text">
                    <h4>About us</h4>
                    <div class="breadcrumb__links">
                        <a href="#home">Home</a>
                        <span>About us</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


  <main id="main">

    <!-- ======= About Section ======= -->
    <section id="about" class="about">
      <div class="container">

        <div class="row justify-content-between">
          <div class="col-lg-5 d-flex align-items-center justify-content-center about-img">
            <img src="assets/img/about-img.svg" class="img-fluid" alt="" data-aos="zoom-in">
          </div>
          <div class="col-lg-6 pt-5 pt-lg-0">
            <h3 data-aos="fade-up">WELCOME TO Thealleviatezone.com</h3>
            <p data-aos="fade-up" data-aos-delay="100">
                Welcome to Thealleviatezone, your go-to source for premium corporate gifting solutions. At Thealleviatezone, we understand the importance of making a lasting impression on your clients, customers, and employees. That's why we offer a wide range of thoughtful and personalized gift options that are sure to impress.
                <br>   <br>
                We have been in the corporate gifting industry for many years, and we pride ourselves on our exceptional customer service and attention to detail. Our team of experts is dedicated to helping you find the perfect gift for any occasion, whether it's a holiday gift for your employees or a thank-you gift for your clients.
                <br>   <br>
                At Thealleviatezone, we believe that corporate gifting is more than just a gesture; it's an opportunity to build strong and lasting relationships with the people who matter most to your business. That's why we work closely with each of our clients to understand their unique needs and preferences, ensuring that every gift we provide is personalized and tailored to their specific requirements.
                <br>   <br>
                Whether you need to send gifts to multiple locations or require custom branding on your gifts, we have the expertise and resources to make it happen. Our commitment to quality and excellence is reflected in everything we do, from the premium products we offer to the seamless delivery of your gifts.
                <br>   <br>
                Thank you for considering Thealleviatezone for your corporate gifting needs. We look forward to helping you create memorable experiences and build meaningful connections with the people who matter most to your business.
                
            </p>
            <!-- <div class="row">
              <div class="col-md-6" data-aos="fade-up" data-aos-delay="100">
                <i class="bx bx-receipt"></i>
                <h4>Corporis voluptates sit</h4>
                <p>Consequuntur sunt aut quasi enim aliquam quae harum pariatur laboris nisi ut aliquip</p>
              </div>
              <div class="col-md-6" data-aos="fade-up" data-aos-delay="200">
                <i class="bx bx-cube-alt"></i>
                <h4>Ullamco laboris nisi</h4>
                <p>Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt</p>
              </div>
            </div> -->
          </div>
        </div>

      </div>
    </section><!-- End About Section -->

    <!-- ======= Services Section ======= -->
   

   




 

  </main><!-- End #main -->


   