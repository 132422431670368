<!-- Pricing Start -->
<div class="container-xxl">
  <div class="row g-4">
    <div
      class="col-lg-12 col-md-6 wow fadeInUp"
      data-wow-delay="0.1s"
      style="
        visibility: visible;
        animation-delay: 0.1s;
        animation-name: fadeInUp;
      "
    >
      <div
        class=""
      >
        <div class="d-flex justify-content-center p-3">
          <a class="btn text-white  m-1" [ngStyle]="{ 'background-color': buttonColor('texttouppercase') }" (click)="texttouppercase('texttouppercase')"
            >Text to UPPERCASE</a
          >
          <a class="btn text-white  m-1" [ngStyle]="{ 'background-color': buttonColor('texttolowercase') }" (click)="texttolowercase('texttolowercase')"
            >Text to lowercase</a
          >
          <a class="btn text-white  m-1" [ngStyle]="{ 'background-color': buttonColor('texttocamelcase') }" (click)="texttocamelcase('texttocamelcase')"
            >Text to camelCase</a
          >
          <a class="btn text-white  m-1" [ngStyle]="{ 'background-color': buttonColor('texttotitle') }" (click)="texttotitle('texttotitle')"
            >Text to Title</a
          >
          <a class="btn text-white  m-1" [ngStyle]="{ 'background-color': buttonColor('texttocapitals') }" (click)="texttocapitals('texttocapitals')"
            >InVerse Case</a
          >
          <!-- <a class="btn  text-primary bg-white m-1" href="">Text to UPPERCASE</a> -->
        </div>
      </div>
    </div>
  </div>
  <div class="">
    <div
      class="section-title position-relative text-center mx-auto mb-5 pb-4 wow fadeInUp"
      data-wow-delay="0.1s"
      style="max-width: 600px"
    >
        <!-- <h1 class="mb-3">Text to camelCase Online Conterter</h1>
        <p class="lead mb-2">
            Convert your text to camel case:
            <code>Simple TEXT_here =&gt; simpleTextHere</code>
        </p> -->
      <!-- <p class="mb-1">  Transform all your text characters to camel case. Camel Case also known as
                        camel caps or more formally as medial capitals. Just paste your text to
                        the tool in the first textarea and convert it immediately!</p> -->
    </div>
    <div class="row gy-5 gx-4">
      <div class="col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="0.2s">
        <div
          class="position-relative shadow rounded border-top border-5 border-primary"
        >
         
          <div class="text-center border-bottom p-4 pt-5">
            <h4 class="fw-bold">Paste your text here:</h4>
            <textarea
              (keyup)="onChangeEvent($event)"
              [(ngModel)]="inputString"
              rows="8"
              id="text"
              placeholder="You text here"
              class="form-control"
              spellcheck="false"
            ></textarea>
          </div>
          <div class="text-center border-bottom p-4">
            <a class="btn btn-primary px-4 py-2" (click)="clear()" >Clear</a>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="0.4s">
        <div
          class="position-relative shadow rounded border-top border-5 border-secondary"
        >
     
          <div class="text-center border-bottom p-4 pt-5">
            <h4 class="fw-bold">Result</h4>
            <textarea
              rows="8"
              [(ngModel)]="outputString"
              readonly="readonly"
              id="text2"
              placeholder="Result will be here"
              class="form-control"
            ></textarea>
          </div>
          <div class="text-center border-bottom p-4">
            <a class="btn btn-secondary px-4 py-2" (click)="copy()" href="">
              Copy</a
            >
            &nbsp;
            <!-- <a class="btn btn-success px-4 py-2" (click)="download()" href=""
              >Download</a
            > -->
            <button class="btn" (click)="download()"><i class="fa fa-download" style="cursor: pointer;"></i> Download</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Pricing End -->

<!-- <div class="container">
    <div class="row my-3">
      <div class="col-12">
        <h1>Text to camelCase Online Conterter</h1>
        <p class="lead mb-2">
          Convert your text to camel case:
          <code>Simple TEXT_here =&gt; simpleTextHere</code>
        </p>
      </div>
      <div class="col-md-9 col-sm-12">
        Transform all your text characters to camel case. Camel Case also known as
        camel caps or more formally as medial capitals. Just paste your text to
        the tool in the first textarea and convert it immediately!
      </div>
    </div>
    <div class="form-group">
      <label for="inputString">Enter Sentence:</label>
     <textarea
        type="text"
        class="form-control"
        id="inputString"
        (keyup)="onChangeEvent($event)"
        [(ngModel)]="inputString"
      ></textarea> -->
<!--  </div>
    <div class="form-group">
      <label for="inputString">Enter Sentence:</label>
      <textarea class="alert alert-success" [(ngModel)]="outputString"></textarea> -->
<!-- {{outputString}} -->
<!--   </div>
  
    <div class="row my-3">
      <div class="col-md-6">
       <label for="text">Paste your text here:</label>
    
        <button class="btn btn-light my-3">Clear</button>
      </div>
      <div class="col-md-6">
        <label for="text2 bold">Result:</label>
      
        <button data-clipboard-target="#text2"  (click)="copy()" class="copy btn btn-success my-3">
          Copy to clipboard
        </button>
        <button class="btn btn-primary" (click)="download()">Download to TXT file</button>
      </div>
    </div>
  </div> -->
